<template>
  <div class="onlint-downbg pb-164">
    <!-- 蒙层-->
    <div class="mask" id="mask" v-show="showShadow">
      <img class="lujing" src="@/assets/imgs/download/lujing.png" />
      <div class="yindao">
        <img
          class="liulanqi"
          src="@/assets/imgs/download/liulanqi.png"
          alt=""
        />
        <span class="desc">点击右上角选择浏览器打开</span>
      </div>
    </div>
    <div class="fixtop" v-show="showFixed">
      <div class="logo-icon">
        <img src="@/assets/imgs/online/download/logo-icon.png" />
      </div>
      <div class="topload" @click="load">立即下载</div>
    </div>
    <img src="@/assets/imgs/online/download/title.png" class="title" alt="" />
    <div class="online-down-btn mt-minus12" @click="load">立即下载</div>
    <div class="imgbox">
      <img src="@/assets/imgs/online/download/kkonline.png" alt="" />
      <img src="@/assets/imgs/online/download/registration-fee.png" alt="" />
      <img src="@/assets/imgs/online/download/learner-management.png" alt="" />
      <img src="@/assets/imgs/online/download/class-schedule.png" alt="" />
    </div>
    <div class="online-down-btn" @click="load">立即下载</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isiOS: false,
      isInWechat: false, //是否微信内打开
      showShadow: false, //微信展示引导层
      showFixed: false, //吸顶下载层展示
    };
  },
  created() {
    this.getScene();
  },
  mounted() {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop >= 200) {
        this.showFixed = true;
      } else {
        this.showFixed = false;
      }
    });
  },
  methods: {
    getScene() {
      let u = navigator.userAgent;
      // 是否是ios终端
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      // ipad ios
      if (navigator.platform.indexOf("MacIntel") > -1) {
        this.isiOS = true;
      }
      // 华为、小米平板
      if (navigator.platform.indexOf("Linux") > -1) {
        this.isiOS = false;
      }
      // 是否在微信里面
      if (u.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {
        this.isInWechat = true;
      }
    },
    load() {
      if (this.isInWechat) {
        this.showShadow = true;
        return;
      }
      if (this.isiOS) {
        window.location.href =
          "itms-apps://itunes.apple.com/cn/app/id1578620084?mt=8";
      } else {
        window.location.href =
          "https://kuke-prod-new.oss-cn-beijing.aliyuncs.com/app_package/kk_online/kkzx.apk";
      }
    },
  },
};
</script>
<style scoped>
.onlint-downbg {
  background: #f2f5fe;
  min-height: 100vh;
  font-size: 24px;
  width: 750px;
  font-family: PingFangSC-Semibold, PingFang SC;
}
.title {
  height: 401px;
}
.onlint-downbg img {
  max-width: 100%;
}
.online-down-btn {
  color: #126efe;
  border: 2px solid #126efe;
  border-radius: 44px;
  width: 360px;
  height: 88px;
  text-align: center;
  line-height: 88px;
  font-weight: 500;
  margin: 0 auto;
  font-size: 32px;
}
.mt-minus12 {
  position: relative;
  margin-top: -28px;
}
.imgbox {
  display: flex;
  flex-wrap: wrap;
}
.imgbox img {
  height: 824px;
}
.pb-164 {
  padding-bottom: 164px;
}
.fixtop {
  position: fixed;
  width: 750px;
  top: 0;
  left: 0;
  height: 128px;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
  z-index: 2;
}
.logo-icon {
  margin-left: 32px;
}
.logo-icon img {
  width: 96px;
  height: 96px;
}
.topload {
  color: #fff;
  background: #126efe;
  border-radius: 36px;
  margin-right: 32px;
  width: 160px;
  height: 64px;
  line-height: 64px;
  text-align: center;
}
.mask {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999999;
}

.lujing {
  width: 120px;
  height: 136px;
  position: absolute;
  top: 20;
  right: 47px;
}

.yindao {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 170px;
}

.yindao .liulanqi {
  width: 48px;
  height: 48px;
  margin-right: 20px;
}

.yindao .desc {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  line-height: 56px;
  letter-spacing: 1px;
}
</style>
